import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { Observable } from 'rxjs/Observable';
import { PaginatorOptions, SortOptions } from '@kdo/ng-crud';
import { ActivatedRoute } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { ListHelperService } from '@kdo/ng-crud';
import * as _ from 'lodash';
import { DialogService } from '../../dialog/dialog.service';
import { ResponsibilityDataSource } from '../responsibility';
import { responsibilityActions, CustomFilterResponsibility, SetResponsibilityFilter } from '../actions/responsibility.actions';
import { responsibilitySelectors } from '../reducers/responsibility.reducer';


@Component({
  selector: 'app-responsibility-list',
  templateUrl: './responsibility-list.component.html',
  styleUrls: ['./responsibility-list.component.scss']
})
export class ResponsibilityListComponent {

  filter: any;
  displayedColumns = ['traegerID', 'name', 'eigeneID', 'actions'];
  pageEvent: any;
  loading$: Observable<boolean>;
  paginatorOps$: Observable<PaginatorOptions>;
  dataSource$: Observable<ResponsibilityDataSource>;
  sortBy$: Observable<SortOptions>;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    protected dialog: DialogService,
    public list: ListHelperService,
  ) {
    this.route.params
      .subscribe(params => {
        this.filter = params;
        this.store.dispatch(new SetResponsibilityFilter(_.omit(params, ['id', 'filter'])));
        this.store.dispatch(new CustomFilterResponsibility(true));
      });
    this.dataSource$ = store.select(responsibilitySelectors.selectList)
      .pipe(map(res => new ResponsibilityDataSource(res)));
    this.paginatorOps$ = store.select(responsibilitySelectors.selectPaginatorOptions);
    this.loading$ = store.select(responsibilitySelectors.getLoading);
    this.sortBy$ = store.select(responsibilitySelectors.selectListSettings).pipe(
      map(settings => JSON.parse(decodeURIComponent(settings.sort)))
    );
  }

  onDelete(id) {
    this.dialog
      .confirm('Träger-ID löschen', 'Sind Sie sicher?')
      .pipe(filter(response => !!response))
      .subscribe(() => {
        this.store.dispatch(responsibilityActions.delete(id));
      });
  }

}
