import { actionTypesFor } from 'app/actions/crud.actions';
import { Action } from '@ngrx/store';
import { Responsibility } from 'app/responsibility/responsibility';
import { HttpResponse } from '@angular/common/http';
import { FilterOptions } from '@kdo/ng-crud';

export const responsibilityActions = actionTypesFor('responsibility');

export const SET_SELECTED = '[Responsibility] Set Selected';
export const SET_RESPONSIBILITY_FILTER = '[Responsibility] SetFilter';
export const GET_OWN_ID = '[Responsibility] Get Own ID';
export const GET_OWN_ID_DONE = '[Responsibility] Get Own ID Done';
export const GET_OWN_ID_ERROR = '[Responsibility] Get Own ID Error';
export const CUSTOM_FILTER_RESPONSIBILITY = '[Responsibility] Custom Filter';
export const CUSTOM_FILTER_RESPONSIBILITY_DONE = '[Responsibility] Custom Filter Done';
export const CUSTOM_FILTER_RESPONSIBILITY_ERROR = '[Responsibility] Custom Filter Error';

export class SetSelectedResponsibility implements Action {
  readonly type = SET_SELECTED;
  constructor(public payload: Responsibility) { }
}

export class GetOwnID implements Action {
  readonly type = GET_OWN_ID;
}

export class GetOwnIDDone implements Action {
  readonly type = GET_OWN_ID_DONE;
  constructor(public id: number) { }
}

export class GetOwnIDError implements Action {
  readonly type = GET_OWN_ID_ERROR;
  constructor(public res: HttpResponse<any>) { }
}

export class SetResponsibilityFilter implements Action {
  readonly type = SET_RESPONSIBILITY_FILTER;
  constructor(public payload: FilterOptions) {}
}

export class CustomFilterResponsibility implements Action {
  readonly type = CUSTOM_FILTER_RESPONSIBILITY;
  constructor(public showAll: boolean) { }
}

export class CustomFilterResponsibilityDone implements Action {
  readonly type = CUSTOM_FILTER_RESPONSIBILITY_DONE;
  constructor(public payload: any) { }
}

export class CustomFilterResponsibilityError implements Action {
  readonly type = CUSTOM_FILTER_RESPONSIBILITY_ERROR;
}
