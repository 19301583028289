import { DataSource } from '@angular/cdk/collections';
import { merge } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

export interface IRequest {
  id?: string;
  traeger?: {
    id?: string;
    entityVersion?: string;
    lastUpdate?: string;
    creationDate?: string;
    traegerID?: string;
    name?: string;
    eigeneID?: boolean
  };
  thvbID: string;
  erstellDatum: string;
  datumFeststellungZustaendigkeit: string;
  budgetBeantragt: string;
  kommentar: string;
  weiterleitung: string;
  antragWeitergeleitet: boolean;
  antragWeitergeleitetP14A3: boolean;
  leistungenMedizinischeReha: boolean;
  leistungenTeilhabeArbeitsleben: boolean;
  leistungenTeilhabeBildung: boolean;
  leistungenSozialeTeilhabe: boolean;
  entscheidung: {
    datumEntscheidungGesamtantrag: string;
    erledigungsartGesamtantrag: string;
    datumBewilligungsbescheid: string;
    datumEntscheidungLMR: string;
    erledigungsartLMR: string;
    datumEntscheidungLTA: string;
    erledigungsartLTA: string;
    datumEntscheidungLTB: string;
    erledigungsartLTB: string;
    datumEntscheidungLST: string;
    erledigungsartLST: string;
    datumLeistungsbeginn: string;
    budgetBewilligt: string;
  };
  gutachten: [
    {
      nr: string;
      datumAuftrag: string;
      datumVorliegen: string;
      leistungenMedizinischeReha: boolean;
      leistungenTeilhabeArbeitsleben: boolean;
      leistungenTeilhabeBildung: boolean;
      leistungenSozialeTeilhabe: boolean;
    }
  ];
  anzahlVersandteMitteilungen: string;
  erstattungsantrag: [
    {
      nr: string;
      datumErledigung: string;
      erledigungsart: string;
    }
  ];
  beschaeftigungNachLeistungsende: boolean;
  datumLTAMassnahmeEnde: string;
  erstattungsverfahren: [
    {
      nr: string;
      datumVerfahren: string;
    }
  ];
  widersprueche: [
    {
      nr: string;
      datumErledigung: string;
      erledigungsart: string;
    }
  ];
  klagen: [
    {
      nr: string;
      datumErledigung: string;
      erledigungsart: string;
    }
  ];
}

export const FormToggles = {
  informationenZumTraeger: true,
  antrag: true,
  erledigungBewilligung: false,
  gutachten: false,
  teilhabeplanung: false,
  erstattungsantraege: false,
  widersprueche: false,
  klagen: false,
  beschaeftigung: false,
  teilhabe: false,
};

export enum AntwortBudgetEnum {
  NEIN = 'Nein',
  JA_TRAEGERSPEZIFISCH = 'Ja, trägerspezifisch',
  JA_TRAEGERUEBERGREIFEND = 'Ja, trägerübergreifend'
}

export enum ErledigungsartGesamtantrag {
  VOLLSTAENDIGE_ABLEHNUNG = 'VOLLSTAENDIGE_ABLEHNUNG',
  NICHT_VOLLSTAENDIGE_ABLEHNUNG = 'NICHT_VOLLSTAENDIGE_ABLEHNUNG',
  VOLLSTAENDIGE_BEWILLIGUNG = 'VOLLSTAENDIGE_BEWILLIGUNG',
  SONSTIGE = 'SONSTIGE',
  WEITERLEITUNG_NACH_P14_A3 = 'WEITERLEITUNG_NACH_P14_A3',
  WEITERLEITUNG_NACH_P14 = 'WEITERLEITUNG_NACH_P14'
}

export enum ErledigungsartEnum {
  VOLLSTAENDIGE_ABLEHNUNG = 'Vollständige Ablehnung',
  NICHT_VOLLSTAENDIGE_ABLEHNUNG =
    'Nicht vollständige Bewilligung (teilweise Bewilligungen und Bewilligungen mit anderer als der beantragten Leistung)',
  VOLLSTAENDIGE_BEWILLIGUNG = 'Vollständige Bewilligung',
  SONSTIGE = 'Sonstige (z.B. Tod des Antragstellers, Antragsrücknahmen, etc.)',
  WEITERLEITUNG_NACH_P14_A3 = 'Weiterleitung nach § 14 Abs. 3 SGB IX',
  WEITERLEITUNG_NACH_P14 = 'Weiterleitung nach § 14 Abs. 1 S. 2 SGB IX'
}

export enum Erledigungsarten {
  LMR = 'erledigungsartLMR',
  LTA = 'erledigungsartLTA',
  LTB = 'erledigungsartLTB',
  LST = 'erledigungsartLST'
}

export enum Leistungsarten {
  LMR = 'leistungenMedizinischeReha',
  LTA = 'leistungenTeilhabeArbeitsleben',
  LTB = 'leistungenTeilhabeBildung',
  LST = 'leistungenSozialeTeilhabe'
}

export enum ErledigungsArtLeistungEnum {
  VOLLSTAENDIGE_ABLEHNUNG = 'Vollständige Ablehnung',
  NICHT_VOLLSTAENDIGE_ABLEHNUNG =
    'Nicht vollständige Bewilligung (teilweise Bewilligungen und Bewilligungen mit anderer als der beantragten Leistung)',
  VOLLSTAENDIGE_BEWILLIGUNG = 'Vollständige Bewilligung',
  SONSTIGE = 'Sonstige (z.B. Tod des Antragstellers, Antragsrücknahmen, etc.)'
}

export enum ErstattungsantragArtEnum {
  BEWILLIGUNG = 'Bewilligung (vollständig und teilweise) = Erstattungen in voller und nicht voller Höhe',
  ABLEHNUNG = 'Ablehnung (= keine Erstattung)'
}

export enum Weiterleitungsart {
  KEINE = 'KEINE',
  NACH_14_1 = 'NACH_14_1',
  NACH_14_3 = 'NACH_14_3'
}

export enum WiderspruchKlageEnum {
  ERFOLGREICH = 'Erfolgreich aus Sicht des Leistungsberechtigten (inkl. Vergleiche, Teilanerkenntnisse, Teilabhilfe)',
  NICHT_ERFOLGREICH = 'Nicht erfolgreich aus Sicht des Leistungsberechtigten'
}

export interface RequestFilterOptions {
  page?: number;
  size?: number;
  filter?: any[];
  sort?: string;
  id?: string;
  von?: string;
  bis?: string;
}

export const GutachtenFormGroup = {
  nr: '',
  datumAuftrag: '',
  datumVorliegen: '',
  leistungenMedizinischeReha: false,
  leistungenTeilhabeArbeitsleben: false,
  leistungenTeilhabeBildung: false,
  leistungenSozialeTeilhabe: false,
};

export const ErstattungsverfahrenFormGroup = {
  nr: '',
  datumVerfahren: ''
};

export const DefaultFormGroup = {
  nr: '',
  datumErledigung: '',
  erledigungsart: ''
};

export enum AntragInfoTextEnum {
  // key === formControlName
  thvbID = '<ul><li>Frei belegbar (z. B. Aktenzeichen+Antragszähler)</li>'
    + '<li>IDs nicht doppelt vergeben!</li>'
    + '<li>ID dient der Identifikation des Antrages; wird nicht übermittelt</li></ul>',
  erstellDatum = '<ul><li>Anzugeben ist der exakte Tag des Antragseingangs oder der Antragsaufnahme (TT.MM.JJJJ)</li>'
    + '<li>a) Ein Antrag auf Leistungen liegt vor, wenn die Unterlagen,'
      + 'die eine Beurteilung der Zuständigkeit ermöglichen, vorliegen.</li>'
    + '<li>b) Bei Handeln von Amts wegen: Antragsdatum = Kenntnis des voraussichtlichen RehaBedarfes</li>'
    + '<li>in beiden Fällen: Insbesondere Identität und konkretisierbares Leistungsbegehren sind erkennbar.</li>'
    + '<li>nicht nötig: geprüfte Wesentlichkeit, Bedürftigkeit oder Hilfeplan</li>'
    + '</ul>',
  datumFeststellungZustaendigkeit =
    '<ul><li>Anzugeben ist der Tag der Feststellung der eigenen Zuständigkeit bzw. Unzuständigkeit (TT.MM.JJJJ)</li>'
    + '<li>Für EinglH maßgeblich: geprüfte örtliche und sachliche Zuständigkeit</li>',
  budgetBeantragt = '<ul><li>Persönliche Budgets unabhängig von der Zahl der berücksichtigten Leistungen angeben</li>'
    + '<li>Teilweise Bewilligungen sind als „bewilligt“ anzugeben</li>'
    + '<li>Meldung trägerübergreifender Budgets nur durch den Beauftragten! (andernfalls: „nein“ wählen)</li>'
    + '<li>Trägerübergreifende Budgets müssen mindestens eine Reha-Leistung nach SGB IX beinhalten</li></ul>',
  // manuell vergebene keys
  leistungen = '<ul><li>Anzugeben ist/sind die Leistungsgruppe(n) der beantragten Leistung(en)</li>'
    + '<li>Mehrfachauswahl möglich</li>'
    + '<li>Nicht zu erfassen: Anträge auf „unterhaltssichernde und ergänzende Leistungen</li>',
  antragWeitergeleitet = '<ul><li>„Antrag weitergeleitet nach § 14 (1) S. 2, wegen vollständiger Unzuständigkeit“ für alle Fälle angeben, wenn als Träger insgesamt unzuständig; '
    + 'nicht bei Antragssplitting (§ 15 Abs. 1 SGB IX).</li>'
    + '<li>Als Weiterleitung gelten auch solche innerhalb der gleichen Trägergruppe.</li>'
    + '<li>Bei Weiterleitung sind in der Regel keine der weiteren Felder relevant.</li></ul>',
  antragWeitergeleitetP14A3 = '<ul><li>„Antrag weitergeleitet nach § 14 (3), Turboklärung“ für alle Fälle angeben, wenn als zweitangegangener '
    + 'Träger unzuständig und der voraussichtlich zuständige Träger als Adressat des Antrags mit der Turbo-Klärung '
    + 'einverstanden ist; nicht bei Antragssplitting (§ 15 Abs. 1 SGB IX).</li>'
    + '<li>Als Weiterleitung gelten auch solche innerhalb der gleichen Trägergruppe.</li>'
    + '<li>Bei Weiterleitung sind in der Regel keine der weiteren Felder relevant.</li></ul>'
}

export enum ErledigungInfoTextEnum {
  // key === formControlName
  datumLeistungsbeginn = '<ul><li>Anzugeben ist der Tag der erstmaligen Inanspruchnahme bzw. des Antritts einer Leistung (TT.MM.JJJJ)</li>'
    + '<li>Bei Gutscheinen ggf. nicht bestimmbar</li>'
    + '<li>Kann ggf. vor dem Datum der Leistungsentscheidung liegen.</li></ul>',
  budgetBewilligt = '<ul><li>Persönliche Budgets unabhängig von der Zahl der berücksichtigten Leistungen angeben</li>'
    + '<li>Teilweise Bewilligungen sind als „bewilligt“ anzugeben</li>'
    + '<li>Meldung trägerübergreifender Budgets nur durch den Beauftragten! (andernfalls: „nein“ wählen)</li>'
    + '<li>Trägerübergreifende Budgets müssen mindestens eine Reha-Leistung nach SGB IX beinhalten</li></ul>',
  // manuell vergebene keys
  erledigungsDatum = '<ul><li>V05: Anzugeben ist der Tag der Entscheidung über den Gesamtantrag (Bescheiddatum: TT.MM.JJJJ)</ul></li>',
  erledigungsArt =
    '<ul><li>Falls ein Antrag zurückgenommen wird oder die antragsstellende Person verstirbt, ist dies als „Sonstige“ zu zählen.</li>'
    + '<li>Bei „nicht vollständiger Bewilligung“ weicht die bewilligte Leistung in Art oder Umfang von der beantragten Leistung ab.</li>'
    + '<li>Falls mehrere Leistungen beantragt und nur ein Teil bewilligt wird, ist dies als „nicht vollständige Bewilligung“ zu zählen</li>'
    + '<li>„Vollständige Bewilligung“: Leistung wird identisch bewilligt wie beantragt.</li></ul>'
}

export enum GutachtenInfoTextEnum {
  // key === formControlName
  datumAuftrag = '<ul><li>Anzugeben ist das genaue Datum der Beauftragung (ohne Postlaufzeit) (TT.MM.JJJJ).</li>'
    + '<li>Beratende Stellungnahmen gelten nicht als Gutachten.</li>'
    + '<li>Bei mehr als 5 Gutachten ist statt des 5. Gutachtens das letzte anzugeben.</li>',
  datumVorliegen = '<ul><li>Anzugeben ist der Tag des Posteingangs beim Träger (Posteingangsstempel) (TT.MM.JJJJ). Bei elektronischen '
    + 'Gutachten kann dies der Zeitpunkt sein, zu dem ein Gutachten bspw. im Fachverfahren zur Weiterverarbeitung vorliegt.</li>'
    + '<li>Beratende Stellungnahmen gelten nicht als Gutachten – Erfassungshinweise beachten!</li>'
    + '<li>Bei mehr als 5 Gutachten ist immer auch das zuletzt vorliegende Gutachten miterfasst sein.</li></ul>'
}

export enum TeilhabeplanInfoTextEnum {
  // key === formControlName
  erstellDatum = 'Anzugeben ist der erste Geltungstag des Plans; wenn nicht vorliegend das Datum der vollständigen Erstellung (TT.MM.JJJJ)',
  // manuell vergebene keys
  defaultAntrag = '<ul><li>"THP suchen"/"Neuen THP hinzufügen" wenn eine Teilhabeplanung nach § 19 SGB IX durchgeführt wurde.</li>'
    + '<li>Gesamt-/Hilfepläne mit einer Leistung gelten als "ohne THP"</li>'
    + '<li>Meldung trägerübergreifender Teilhabeplanungen nur durch leistenden Reha-Träger! (andernfalls: keinen THP angeben)</li></ul>',
  art = '„Trägerübergreifend“ = Beteiligung mehrere Reha-Träger (aus verschiedenen Trägergruppen)',
  anpassungen = '<ul><li>Anzugeben ist die Zahl der Anpassungen (fortlaufender Zähler).</li>'
    + '<li>Nur anzugeben durch den leistenden Träger bzw. Verantwortlichen für den Teilhabeplan</li>'
    + '<li>Anpassungen = jede Fortschreibung + Änderung (nach erstmaliger Erstellung des THP)</li>'
    + '<li>Anpassung = Anpassung der Inhalte des Plans (§ 19 SGB IX) an den Verlauf der Rehabilitation</li>'
    + '<li>Bei neuem Planungsprozess, der eine grundsätzlich neue Zusammenstellung der Inhalte zum Ziel hat:'
    + 'keine Anpassung, sondern(Erstelldatum des Teilhabeplans V13).</li>'
    + '<li>Anzugeben ist der erste Geltungstag des angepassten Plans (TT.MM.JJJJ).</li>'
    + '<li>Nur anzugeben durch den leistenden Träger bzw. Verantwortlichen für den Teilhabeplan</li></ul>'
}

export enum ErstattungsAntraegeInfoTextEnum {
  // key === formControlName
  erledigungsart = 'Anzugeben sind Anträge nach § 18 SGB IX und deren Ergebnis.',
  datumErledigung = 'Anzugeben sind Anträge nach § 18 SGB IX und deren Ergebnis.',
  anzahlVersandteMitteilungen = 'Anzugeben ist die Zahl der Mitteilungen nach § 18 (1) (Fortlaufender Zähler).',
}

export enum WiderspruchInfoTextEnum {
  defaultAntrag = 'Anzugeben sind Widersprüche gegen Leistungsbescheide zu Leistungen nach SGB IX Teil 1 '
    + 'unabhängig vom konkreten Gegenstand des Widerspruchs.'
}

export enum KlagenInfoTextEnum {
  defaultAntrag = '<ul><li>Anzugeben sind Klagen gegen Leistungsbescheide zu Leistungen nach SGB IX Teil 1 '
    + 'unabhängig vom konkreten Gegenstand der Klage</li>'
    + '<li>Nur rechtskräftige Urteile und bestandskräftige Entscheidungen (inkl. Vergleiche)</li></ul>'
}

export enum ErstattungsVerfahrenTextEnum {
  defaultAntrag = 'Anzugeben ist die Zahl der gegen andere Reha-Träger angestrengten '
    + 'Erstattungsverfahren nach § 16 (2) S. 2 (Fortlaufender Zähler).',
  nichtMoeglichWennAntragAbgelehnt = 'Erstattungsverfahren gegen andere Reha-Träger '
    + 'können nicht eingeleitet werden, wenn der Gesamtantrag vollständig abgelehnt wurde.'
}

export enum TeilhabeTextEnum {
  beschaeftigungNachLeistungsende = 'Für EinglH, KiJuHi und KOF/KOV: ist zunächst nicht zu belegen!'
}

export const InfoTexts = {
  antrag: AntragInfoTextEnum,
  erledigung: ErledigungInfoTextEnum,
  gutachten: GutachtenInfoTextEnum,
  teilhabeplan: TeilhabeplanInfoTextEnum,
  erstattung: ErstattungsAntraegeInfoTextEnum,
  widerspruch: WiderspruchInfoTextEnum,
  klagen: KlagenInfoTextEnum,
  teilhabe: TeilhabeTextEnum,
  erstattungsverfahren: ErstattungsVerfahrenTextEnum,
};

export class RequestDataSource extends DataSource<any> {
  constructor(public data: any) {
    super();
  }

  connect(): Observable<any[]> {
    return merge(this.data).pipe(
      map(() => {
        return this.data.slice();
      })
    );
  }

  disconnect() {}
}
