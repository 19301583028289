import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnInit, AfterContentInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { FormArrayHelperService } from 'app/shared/formArrayHelper.service';
import { NotifyService } from 'app/notify/notify.service';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { requestActions, SaveAndReloadRequest, ClearIntegrationPlan } from '../actions/request.actions';
import {
  FormToggles,
  AntwortBudgetEnum,
  ErledigungsartEnum,
  ErstattungsantragArtEnum,
  GutachtenFormGroup,
  ErstattungsverfahrenFormGroup,
  WiderspruchKlageEnum,
  DefaultFormGroup,
  IRequest,
  ErledigungsArtLeistungEnum,
  InfoTexts,
  ErledigungsartGesamtantrag,
  Weiterleitungsart,
} from '../request';
import { CustomFilterResponsibility, SetResponsibilityFilter } from 'app/responsibility/actions/responsibility.actions';
import { responsibilitySelectors } from 'app/responsibility/reducers/responsibility.reducer';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { tap, filter, map, distinctUntilChanged, share, take } from 'rxjs/operators';
import { requestSelectors, selectLoading, selectCorrespondingIntegrationplan } from '../reducers/request.reducer';
import { MatSelectHelperService } from 'app/shared/matSelectHelper.service';
import { Subscription } from 'rxjs';
import { DialogService } from 'app/dialog/dialog.service';
import * as moment from 'moment';
import { IntegrationPlanPopupFormComponent } from 'app/integration-plan/form/popup/integration-plan-popup-form.component';
import { IntegrationPlan } from 'app/integration-plan/integration-plan';
import { KlagenValidators } from '../validators/klagen.validators';
import { GutachtenValidators } from '../validators/gutachten.validators';
import { AntragValidators } from '../validators/antrag.validators';
import { EntscheidungValidators } from '../validators/entscheidung.validators';
import { ErstattungsverfahrenValidators } from '../validators/erstattungsverfahren.validators';
import { ErstattungsantragValidators } from '../validators/erstattungsantrag.validators';
import { WiderspruecheValidators } from '../validators/widersprueche.validators';
import { DownloadFile } from 'app/importer/actions/importer.actions';
import { selectDownloading } from 'app/importer/reducers/importer.reducer';
import { SaveIntegrationPlan } from 'app/integration-plan/actions/integration-plan.actions';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-request',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestFormComponent implements OnInit, OnDestroy, AfterContentInit {

  readonly antwortBudgetEnum = AntwortBudgetEnum;
  readonly dateFields = [
    'erstellDatum',
    'datumAuftrag',
    'datumVorliegen',
    'datumErledigung',
    'datumVerfahren'
  ];
  readonly defaultFormGroup = DefaultFormGroup;
  readonly erledigungsartEnum = ErledigungsartEnum;
  readonly erledigungsartLeistungEnum = ErledigungsArtLeistungEnum;
  readonly erstattungsantragArtEnum = ErstattungsantragArtEnum;
  readonly erstattungsverfahrenFormGroup = ErstattungsverfahrenFormGroup;
  readonly gutachtenFormGroup = GutachtenFormGroup;
  readonly infoTexts = InfoTexts;
  readonly minDate = moment('01.01.2019', 'DD.MM.YYYY').toISOString();
  readonly object = Object;
  readonly requiredValidator = Validators.required;
  readonly subscription = new Subscription();
  readonly weiterleitung = ErledigungsartGesamtantrag.WEITERLEITUNG_NACH_P14_A3;
  readonly widerspruchKlageEnum = WiderspruchKlageEnum;

  currentInfoText = '';
  currentInfoSection: string;
  currentIntegrationPlan = {} as IntegrationPlan;
  curRequestObject: IRequest;
  defaultFormValue: any;
  filter: any;
  form: FormGroup;
  formToggles = _.cloneDeep(FormToggles);
  params: any;
  returnUrl = '';
  scrollPosition = 0;
  singleTraeger = false;
  validators: {
    antrag: AntragValidators,
    entscheidung: EntscheidungValidators,
    gutachten: GutachtenValidators,
    klagen: KlagenValidators,
    erstattungsantrag: ErstattungsantragValidators,
    erstattungsverfahren: ErstattungsverfahrenValidators,
    widersprueche: WiderspruecheValidators,
  };

  downloading$: Observable<boolean>;
  integrationPlan$: Observable<IntegrationPlan>;
  loading$: Observable<boolean>;
  traeger$: Observable<any>;

  constructor(
    private fb: FormBuilder,
    private notify: NotifyService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<State>,
    private chdet: ChangeDetectorRef,
    private dialog: DialogService,
    antrag: AntragValidators,
    entscheidung: EntscheidungValidators,
    gutachten: GutachtenValidators,
    klagen: KlagenValidators,
    erstattungsverfahren: ErstattungsverfahrenValidators,
    erstattungsantrag: ErstattungsantragValidators,
    widersprueche: WiderspruecheValidators,
    protected matDialog: MatDialog,
    public formHelper: FormArrayHelperService,
    public matSelectHelper: MatSelectHelperService,
  ) {
    this.form = this.fb.group({
      // Informationen zum Träger
      traeger: ['', Validators.required],

      // Antrag
      thvbID: ['', Validators.compose(
        [Validators.maxLength(25), Validators.required]
      )],

      erstellDatum: ['', Validators.required],
      datumFeststellungZustaendigkeit: '',
      datumLTAMassnahmeEnde: '',
      budgetBeantragt: '',
      kommentar: ['', Validators.maxLength(255)],
      weiterleitung: Weiterleitungsart.KEINE,
      leistungenMedizinischeReha: false,
      leistungenTeilhabeArbeitsleben: false,
      leistungenTeilhabeBildung: false,
      leistungenSozialeTeilhabe: false,

      // Erledigung/Bewilligung
      entscheidung: fb.group({
        datumEntscheidungLMR: '',
        erledigungsartLMR: '',

        datumEntscheidungLTA: '',
        erledigungsartLTA: '',

        datumEntscheidungLTB: '',
        erledigungsartLTB: '',

        datumEntscheidungLST: '',
        erledigungsartLST: '',

        datumEntscheidungGesamtantrag: { value: '', disabled: true },
        erledigungsartGesamtantrag: { value: '', disabled: true },

        datumBewilligungsbescheid: '',

        datumLeistungsbeginn: '',
        budgetBewilligt: '',
      }),

      // Gutachten
      gutachten: fb.array([]),

      // Erstattungsanträge
      anzahlVersandteMitteilungen: 0,
      erstattungsantrag: fb.array([]),

      // Beschäftigung
      beschaeftigungNachLeistungsende: { value: false, disabled: true },
      erstattungsverfahren: fb.array([]),

      // Widersprüche
      widersprueche: fb.array([]),

      // Klagen
      klagen: fb.array([]),
      beruflicheTeilhabeSection: false,
    });

    this.validators = {
      antrag,
      entscheidung,
      gutachten,
      klagen,
      erstattungsantrag,
      erstattungsverfahren,
      widersprueche
    };

    Reflect.ownKeys(this.validators)
      .forEach(key => Reflect.get(this.validators, key).setForm(this.form));

    this.downloading$ = this.store.select(selectDownloading);
  }

  ngAfterContentInit() {
    this.route.queryParams.pipe(
      filter(params => params.thpHinzufuegen !== undefined),
      filter(params => params.neuerThp !== undefined),
    ).subscribe(params => {
      this.params = params;
    });

    this.form.controls.weiterleitung.valueChanges.subscribe(value => {
      if (value !== Weiterleitungsart.KEINE) {
        (<FormGroup>this.form.controls.entscheidung).controls.budgetBewilligt.patchValue('');
        this.form.controls.beschaeftigungNachLeistungsende.reset();
        this.form.controls.datumLTAMassnahmeEnde.reset();
      }
    });
  }

  ngOnInit() {
    this.defaultFormValue = this.form.getRawValue();
    this.store.dispatch(new ClearIntegrationPlan());
    this.route.params
      .pipe(
        tap(params => this.filter = params),
        filter(params => params.id),
        map(params => params.id),
        distinctUntilChanged()
      )
      .subscribe(id => this.store.dispatch(requestActions.get(id)));

    this.route.params
      .pipe(
        filter(params => !params.id),
        distinctUntilChanged()
      )
      .subscribe(() => this.store.dispatch(requestActions.set({})));

    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl;
    });

    this.store.select(requestSelectors.selectCurrent).pipe(
      filter(data => !isEmpty(data))
    ).subscribe(data => {
      this.form.reset(this.form.value);
      this.formHelper.initFormArrayValues(this.erstattungsverfahren, data.erstattungsverfahren, this.erstattungsverfahrenFormGroup);
      this.formHelper.initFormArrayValues(this.widersprueche, data.widersprueche, this.defaultFormGroup);
      this.formHelper.initFormArrayValues(this.klagen, data.klagen, this.defaultFormGroup);
      this.formHelper.initFormArrayValues(this.erstattungsantrag, data.erstattungsantrag, this.defaultFormGroup);
      this.formHelper.initFormArrayValues(this.gutachten, data.gutachten, this.gutachtenFormGroup);
      this.form.patchValue(data);
      this.scrollToPosition(this.scrollPosition);
      this.curRequestObject = _.cloneDeep(data);
      this.toggleFormAreas(this.curRequestObject);
      if (data.id) {
        this.form.controls.thvbID.disable();
      }

      if (this.params !== undefined && this.curRequestObject.id && this.form.getRawValue().thvbID) {
        this.showForm(this.params.thpHinzufuegen === 'true', this.params.neuerThp === 'true');
        this.formToggles.teilhabeplanung = true;
        this.router.navigate(['antrag/' + this.params.id]);
        this.params = undefined;
      }
    });

    this.loading$ = this.store.select(selectLoading);
    this.integrationPlan$ = this.store.select(selectCorrespondingIntegrationplan);
    this.integrationPlan$.subscribe(plan => {
      this.currentIntegrationPlan = plan ? plan : this.currentIntegrationPlan;
    });

    this.subscription.add(
      this.form.controls.datumFeststellungZustaendigkeit.valueChanges
        .pipe(filter(date => !date))
        .subscribe(() => {
          this.toggleFormToggles(false, true);
        })
    );

    this.subscription.add(
      this.entscheidung.controls.erledigungsartLMR.valueChanges.subscribe((art: string = '') => {
        if (art === ''
            && this.entscheidung.controls.datumEntscheidungGesamtantrag.value
            && !this.entscheidung.controls.erledigungsartGesamtantrag.value
          ) {
            this.entscheidung.controls.datumEntscheidungGesamtantrag.patchValue('');
        }
      })
    );

    this.subscription.add(
      this.entscheidung.controls.erledigungsartLTA.valueChanges.subscribe((art: string = '') => {
        if (art === ''
          || art === 'VOLLSTAENDIGE_ABLEHNUNG'
          || art === 'SONSTIGE'
        ) {
          this.form.controls.beschaeftigungNachLeistungsende.reset();
          this.form.controls.datumLTAMassnahmeEnde.reset();
        }

        if (art === ''
          && this.entscheidung.controls.datumEntscheidungGesamtantrag.value
          && !this.entscheidung.controls.erledigungsartGesamtantrag.value
        ) {
          this.entscheidung.controls.datumEntscheidungGesamtantrag.patchValue('');
        }
      })
    );

    this.subscription.add(
      this.entscheidung.controls.erledigungsartLTB.valueChanges.subscribe((art: string = '') => {
        if (art === ''
            && this.entscheidung.controls.datumEntscheidungGesamtantrag.value
            && !this.entscheidung.controls.erledigungsartGesamtantrag.value
          ) {
            this.entscheidung.controls.datumEntscheidungGesamtantrag.patchValue('');
        }
      })
    );

    this.subscription.add(
      this.entscheidung.controls.erledigungsartLST.valueChanges.subscribe((art: string = '') => {
        if (art === ''
            && this.entscheidung.controls.datumEntscheidungGesamtantrag.value
            && !this.entscheidung.controls.erledigungsartGesamtantrag.value
          ) {
            this.entscheidung.controls.datumEntscheidungGesamtantrag.patchValue('');
        }
      })
    );

    this.subscription.add(
      this.entscheidung.controls.erledigungsartGesamtantrag.valueChanges.subscribe((art: string = '') => {
        this.validators.entscheidung.valueOfErledigungsartGesamtantragChanges(art);
        if (art === ErledigungsartGesamtantrag.VOLLSTAENDIGE_ABLEHNUNG) {
          this.entscheidung.controls.datumBewilligungsbescheid.reset();
          this.entscheidung.controls.datumLeistungsbeginn.reset();
        }
      })
    );

    this.subscription.add(
      this.form.valueChanges.subscribe(() => this.markFormGroupTouched(this.form))
    );

    this.subscription.add(
      this.form.controls.datumLTAMassnahmeEnde.valueChanges
        .subscribe(date => {
          if (!date || date === '') {
            this.form.controls.beschaeftigungNachLeistungsende.patchValue(false);
            this.form.controls.beschaeftigungNachLeistungsende.disable();
          } else {
            this.form.controls.beschaeftigungNachLeistungsende.enable();
            this.form.controls.beschaeftigungNachLeistungsende.patchValue(true);
          }
          this.chdet.detectChanges();
        })
    );

    // Träger-ID
    this.store.dispatch(new SetResponsibilityFilter({ size: 999 }));
    this.store.dispatch(new CustomFilterResponsibility(false));
    this.traeger$ = this.store.select(responsibilitySelectors.selectList);
    // Selektiere automatisch den ersten Träger, wenn nur einer existiert
    this.traeger$.subscribe(traeger => {
      if (traeger.length === 1) {
        this.form.patchValue({ traeger: traeger[0] });
        this.form.controls.traeger.disable();
        this.singleTraeger = true;
      } else {
        this.singleTraeger = false;
      }
    });
  }

  setFormAsWeitergeleitet(erledigungsArt: string) {
    this.dialog.confirm(
        'Warnung',
        'Wenn Sie die Weiterleitung aktivieren, werden bestehende Formulareingaben in den nachfolgenden Abschnitten gelöscht. '
        + 'Sind Sie sicher, dass Sie die Weiterleitung aktivieren möchten?'
      )
      .subscribe(response => {
        if (response) {
          this.resetFormForWeiterleitung();
          this.validators.antrag.valueOfWeitergeleitetChanges(true);
          this.toggleFormToggles(false, true);
          this.entscheidung.controls.datumEntscheidungGesamtantrag.patchValue(this.form.getRawValue().datumFeststellungZustaendigkeit);
          this.entscheidung.controls.erledigungsartGesamtantrag.patchValue(erledigungsArt);
          this.setEnabledInFormGroup(false, this.entscheidung);
          this.formToggles.erledigungBewilligung = true;
          this.chdet.detectChanges();
        } else {
          this.form.controls.weiterleitung.patchValue('KEINE');
        }
      });
  }

  onChangeWeiterleitung(evt: MatSelectChange) {
    // De-/Aktivieren von Teilbereichen des Formulars
    if (evt.value === Weiterleitungsart.NACH_14_1) {
      this.setFormAsWeitergeleitet(ErledigungsartGesamtantrag.WEITERLEITUNG_NACH_P14);
      this.chdet.detectChanges();
    } else if (evt.value === Weiterleitungsart.NACH_14_3) {
      this.setFormAsWeitergeleitet(ErledigungsartGesamtantrag.WEITERLEITUNG_NACH_P14_A3);
      this.chdet.detectChanges();
    } else if (evt.value === Weiterleitungsart.KEINE) {
      this.validators.antrag.valueOfWeitergeleitetChanges(false);
      this.entscheidung.controls.datumEntscheidungGesamtantrag.reset();
      this.entscheidung.controls.erledigungsartGesamtantrag.reset();
      this.setEnabledInFormGroup(true, this.entscheidung);
      this.chdet.detectChanges();
    }
  }

  confirmDelete(deleteOp: () => void) {
    this.dialog
      .confirm(
        'Möchten Sie die bisher in diesem Abschnitt eingegebenen Daten wirklich löschen?',
        '',
        'Löschen',
        'Abbrechen',
        'warn'
      )
      .pipe(filter(response => !!response))
      .subscribe(() => {
        deleteOp();
        this.chdet.detectChanges();
      });
  }

  resetGutachten = () => {
    this.gutachten.clear();
    this.gutachten.reset();
  }

  resetEntscheidung = () => {
    this.entscheidung.reset();
  }

  resetErstattungsantrag = () => {
    this.form.controls.anzahlVersandteMitteilungen.reset();
    this.erstattungsantrag.clear();
    this.erstattungsantrag.reset();
  }

  resetWidersprueche = () => {
    this.widersprueche.clear();
    this.widersprueche.reset();
  }

  resetKlagen = () => {
    this.klagen.clear();
    this.klagen.reset();
  }

  resetErstattungsverfahren = () => {
    this.erstattungsverfahren.clear();
    this.erstattungsverfahren.reset();
  }

  resetBeruflicheTeilhabe = () => {
    this.form.controls.beschaeftigungNachLeistungsende.reset();
    this.form.controls.datumLTAMassnahmeEnde.reset();
  }

  // Setzt Formularbereiche zurück, die bei Weiterleitung geleert werden sollen.
  resetFormForWeiterleitung() {
    this.resetGutachten();
    this.resetEntscheidung();
    this.resetErstattungsantrag();
    this.resetWidersprueche();
    this.resetKlagen();
    this.resetErstattungsverfahren();
    this.resetBeruflicheTeilhabe();

    this.chdet.detectChanges();
  }

  hasError(formControl: FormControl, errorType: string): boolean {
    return formControl.hasError(errorType);
  }

  hasErrorInFormArray(formArray: FormArray, errorType: string): boolean {
    return formArray.controls.some(group => {
      const formGroup = group as FormGroup;
      return Object.values(formGroup.controls).some(control => control.hasError(errorType));
    });
  }

  /**
   * Gibt das größte Erledigungsdatum zurück.
   * Wenn keines vorliegt, wird das Datum der Feststellung der Zuständigkeit zurückgegeben.
   */
  getMaxDateForErledigungsdatum(): Date {
    const validDates = [];
    if (!!this.form.getRawValue().entscheidung.datumEntscheidungLMR) {
      validDates.push(this.form.getRawValue().entscheidung.datumEntscheidungLMR);
    }
    if (!!this.form.getRawValue().entscheidung.datumEntscheidungLTA) {
      validDates.push(this.form.getRawValue().entscheidung.datumEntscheidungLTA);
    }
    if (!!this.form.getRawValue().entscheidung.datumEntscheidungLTB) {
      validDates.push(this.form.getRawValue().entscheidung.datumEntscheidungLTB);
    }
    if (!!this.form.getRawValue().entscheidung.datumEntscheidungLST) {
      validDates.push(this.form.getRawValue().entscheidung.datumEntscheidungLST);
    }
    if (validDates.length > 0) {
      return moment.max(validDates.map(date => moment(date))).toDate();
    } else {
      return this.form.getRawValue().datumFeststellungZustaendigkeit;
    }
  }

  // Gibt aus der Kombination zweier Entscheidungsarten die resultierende Entscheidungsart zurück
  getResultierendeEntscheidungsart(erledigungsart1: string, erledigungsart2: string): string {
    switch (erledigungsart1) {
    case ErledigungsartGesamtantrag.VOLLSTAENDIGE_ABLEHNUNG:
        if (erledigungsart2 === ErledigungsartGesamtantrag.VOLLSTAENDIGE_ABLEHNUNG
          || erledigungsart2 === ErledigungsartGesamtantrag.SONSTIGE) {
        return ErledigungsartGesamtantrag.VOLLSTAENDIGE_ABLEHNUNG;
      } else {
        return ErledigungsartGesamtantrag.NICHT_VOLLSTAENDIGE_ABLEHNUNG;
      }
    case ErledigungsartGesamtantrag.NICHT_VOLLSTAENDIGE_ABLEHNUNG:
      return ErledigungsartGesamtantrag.NICHT_VOLLSTAENDIGE_ABLEHNUNG;
    case ErledigungsartGesamtantrag.VOLLSTAENDIGE_BEWILLIGUNG:
        if (erledigungsart2 === ErledigungsartGesamtantrag.VOLLSTAENDIGE_ABLEHNUNG
          || erledigungsart2 === ErledigungsartGesamtantrag.NICHT_VOLLSTAENDIGE_ABLEHNUNG) {
        return ErledigungsartGesamtantrag.NICHT_VOLLSTAENDIGE_ABLEHNUNG;
      } else {
        return ErledigungsartGesamtantrag.VOLLSTAENDIGE_BEWILLIGUNG;
      }
    case ErledigungsartGesamtantrag.SONSTIGE:
      return erledigungsart2;
    default:
      break;
    }
  }

  // Ermittelt aus allen Entscheidungsarten der Leistungsgruppen die Gesamtentscheidung
  getErledigungsArt(): string {
    if (this.form.getRawValue().weiterleitung === Weiterleitungsart.NACH_14_1) {
      return ErledigungsartGesamtantrag.WEITERLEITUNG_NACH_P14;
    } else if (this.form.getRawValue().weiterleitung === Weiterleitungsart.NACH_14_3) {
      return ErledigungsartGesamtantrag.WEITERLEITUNG_NACH_P14_A3;
    }

    const entscheidungen = [];
    let erledigungsartGesamt = '';
    const lmrArt = this.form.getRawValue().entscheidung.erledigungsartLMR ?? '';
    const ltaArt = this.form.getRawValue().entscheidung.erledigungsartLTA ?? '';
    const ltbArt = this.form.getRawValue().entscheidung.erledigungsartLTB ?? '';
    const lstArt = this.form.getRawValue().entscheidung.erledigungsartLST ?? '';

    if (lmrArt !== '') {
      entscheidungen.push(lmrArt);
    }
    if (lstArt !== '') {
      entscheidungen.push(lstArt);
    }
    if (ltaArt !== '') {
      entscheidungen.push(ltaArt);
    }
    if (ltbArt !== '') {
      entscheidungen.push(ltbArt);
    }

    entscheidungen.forEach((entscheidung, index) => {
      if (index === 0) {
        erledigungsartGesamt = entscheidung;
      } else {
        erledigungsartGesamt = this.getResultierendeEntscheidungsart(erledigungsartGesamt, entscheidung);
      }
    });

    return erledigungsartGesamt;
  }

  getFilteredErledigungsArten(erledigungsArt: string): string[] {
    const lmr = this.form.getRawValue().leistungenMedizinischeReha;
    const lmrArt = this.form.getRawValue().entscheidung.erledigungsartLMR;
    const lta = this.form.getRawValue().leistungenTeilhabeArbeitsleben;
    const ltaArt = this.form.getRawValue().entscheidung.erledigungsartLTA;
    const ltb = this.form.getRawValue().leistungenTeilhabeBildung;
    const ltbArt = this.form.getRawValue().entscheidung.erledigungsartLTB;
    const lst = this.form.getRawValue().leistungenSozialeTeilhabe;
    const lstArt = this.form.getRawValue().entscheidung.erledigungsartLST;

    // Mindestens eine Option muss ungleich 'Vollständige Ablehnung' sein
    const isOptionValid =
         (lmr ? ((erledigungsArt === 'lmr') || (lmrArt === 'VOLLSTAENDIGE_ABLEHNUNG')) : true)
      && (lta ? ((erledigungsArt === 'lta') || (ltaArt === 'VOLLSTAENDIGE_ABLEHNUNG')) : true)
      && (ltb ? ((erledigungsArt === 'ltb') || (ltbArt === 'VOLLSTAENDIGE_ABLEHNUNG')) : true)
      && (lst ? ((erledigungsArt === 'lst') || (lstArt === 'VOLLSTAENDIGE_ABLEHNUNG')) : true)
    ;

    return isOptionValid
      && (!!this.form.getRawValue().entscheidung.datumBewilligungsbescheid || !!this.form.getRawValue().entscheidung.datumLeistungsbeginn)
      ? Object.keys(this.erledigungsartLeistungEnum).filter(value => value !== 'VOLLSTAENDIGE_ABLEHNUNG')
      : Object.keys(this.erledigungsartLeistungEnum)
    ;
  }

  onChangeBeschaeftigungNachLeistungsende(evt: MatCheckboxChange) {
    if (!evt.checked) {
      this.form.controls.datumLTAMassnahmeEnde.reset();
    }
  }

  markFormGroupTouched(group: FormGroup) {
    (<any>Object).values(group.controls).forEach((control: FormGroup) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /**
   * Öffnet einen Formbereich bei Laden der Daten automatisch, sofern darin bereits Daten eingetragen wurden.
   * Ist das Objekt leer, werden standardmäßig alle sonstigen Formbereiche zugeklappt.
   * @param request Objekt mit Daten vom Server. Wird mit dem Default-Wert des Formulars auf Unterschiede verglichen.
   */
  toggleFormAreas(request: IRequest) {
    if (!this.isEmpty(request)) {
      this.formToggles.erledigungBewilligung =
        (request.entscheidung !== this.defaultFormValue.entscheidung) && !!request.datumFeststellungZustaendigkeit;
      this.formToggles.gutachten = request.gutachten.length > 0;
      this.formToggles.teilhabeplanung = this.currentIntegrationPlan.thvbID ? true : false;
      this.formToggles.erstattungsantraege =
        request.anzahlVersandteMitteilungen !== this.defaultFormValue.anzahlVersandteMitteilungen
        || request.erstattungsantrag.length > 0
      ;
      this.formToggles.widersprueche = request.widersprueche.length > 0;
      this.formToggles.klagen = request.klagen.length > 0;
      this.formToggles.beschaeftigung = request.erstattungsverfahren.length > 0;
      this.formToggles.teilhabe =
        request.beschaeftigungNachLeistungsende !== this.defaultFormValue.beschaeftigungNachLeistungsende
        || (request.datumLTAMassnahmeEnde ? request.datumLTAMassnahmeEnde !== this.defaultFormValue.datumLTAMassnahmeEnde : false)
      ;
    } else {
      this.toggleFormToggles(false, true);
    }
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Löscht die Verbindung eines THP zu dem geöffneten Antrag.
   * Darf nur gelöscht werden, wenn der THP aus einem anderen Antrag generiert wurde.
   */
  deleteIntegrationPlan() {
    if (this.form.dirty) {
      this.dialog.confirm(
        'Der Antrag hat ungespeicherte Änderungen.',
        'Vor Löschen eines Teilhabeplans müssen die Änderungen gespeichert werden. Wollen Sie jetzt speichern?'
      ).subscribe(res => {
        if (res) {
          this.store.dispatch(new SaveAndReloadRequest({ ...this.curRequestObject, ...this.form.getRawValue() }, this.filter.id));
        }
      });
      return;
    }
    this.dialog
      .confirm('Verbindung zum Teilhabeplan löschen', 'Sind Sie sicher?')
      .pipe(filter(response => !!response))
      .subscribe(() => {
        const newPlan = _.cloneDeep(this.currentIntegrationPlan);
        if (newPlan.thvbID === this.form.getRawValue().thvbID + '_THP') {
          return;
        }

        newPlan.antraege.forEach((antrag, index) => {
          if (antrag.thvbID === this.form.getRawValue().thvbID) {
            newPlan.antraege.splice(index, 1);
            this.store.dispatch(new SaveIntegrationPlan(newPlan, this.filter.id));
            this.store.dispatch(new ClearIntegrationPlan());
          }
        });
      });
  }

  /**
   * (De-)Aktiviert FormControls innerhalb einer FormGroup
   * @param value true, wenn Felder aktiviert werden sollen
   * @param group FormGroup, dessen Controls (de-)aktiviert werden sollen
   * @param excludedFieldKeys Namen der FormControls, die nicht von der Änderung betroffen sein sollen
   */
  setEnabledInFormGroup(value: boolean, group: FormGroup, excludedFieldKeys?: string[]) {
    Object.keys(group.controls)
      .filter(field => excludedFieldKeys ? !excludedFieldKeys.includes(field) : true)
      .forEach(field => value ? group.controls[field].enable() : group.controls[field].disable())
    ;
  }

  /**
   * Blendet Hinweise im Formular ein
   * @param evt Enthält ggf. formControlName, der dann als key für das Texte-Enum verwendet wird
   * @param section Name des Formularabschnitts
   * @param controlName Manueller key für das Texte-Enum, sofern kein formControlName vorhanden ist
   */
  onFocus(evt: any, section: string, controlName?: string) {
    this.currentInfoSection = section;
    this.currentInfoText = this.infoTexts[section][controlName ? controlName : evt.target.id];
  }

  getCurrentInfoText(): string {
    return this.currentInfoText;
  }

  onSave() {
    this.storeScrollPosition();
    this.store.dispatch(new SaveAndReloadRequest({ ...this.curRequestObject, ...this.form.getRawValue() }, this.filter.id));
  }

  onDelete() {
    this.dialog
      .confirm('Antrag löschen', 'Sind Sie sicher?')
      .pipe(filter(response => !!response))
      .subscribe(() => {
        this.store.dispatch(requestActions.delete(this.filter.id));
      });
  }

  download(id: string, fileName: string): void {
    this.store.dispatch(new DownloadFile(id, 'antrag', fileName));
  }

  toggleFormToggles(newStatus: boolean, toggleAll: boolean) {
    this.formToggles.erledigungBewilligung = newStatus;
    this.formToggles.erstattungsantraege = newStatus;
    this.formToggles.beschaeftigung = newStatus;
    this.formToggles.teilhabe = newStatus;
    this.formToggles.klagen = newStatus;
    this.formToggles.widersprueche = newStatus;
    if (toggleAll) {
      this.formToggles.gutachten = newStatus;
      this.formToggles.teilhabeplanung = newStatus;
    }
  }

  showForm(thpButtonPressed: boolean, isNewThp: boolean): void {
    this.storeScrollPosition();
    if (this.form.dirty) {
      this.dialog.confirm(
        'Der Antrag hat ungespeicherte Änderungen.',
        'Vor Hinzufügen eines Teilhabeplans müssen die Änderungen gespeichert werden. Wollen Sie jetzt speichern?'
      ).subscribe(res => {
        if (res) {
          this.store.dispatch(new SaveAndReloadRequest(
            { ...this.curRequestObject, ...this.form.getRawValue() },
            this.filter.id,
            thpButtonPressed,
            isNewThp
          ));
        }
      });
      return;
    }
    let integrationPlanId: string;
    if (thpButtonPressed && !isNewThp) {
      this.integrationPlan$.pipe(take(1)).subscribe(plan => integrationPlanId = (plan && plan.id) ? plan.id : '');
      integrationPlanId = !!integrationPlanId ? integrationPlanId : this.currentIntegrationPlan.id;
    }
    const rx = this.matDialog.open(IntegrationPlanPopupFormComponent, {
      data: {
        antragsThvbId: this.form.getRawValue().thvbID,
        antragsId: this.curRequestObject.id,
        thpButtonPressed: thpButtonPressed,
        isNewThp: isNewThp,
        integrationPlanId: integrationPlanId
      },
      disableClose: true,
      maxWidth: '90vw',
      width: '1000px',
    }).afterClosed()
      .pipe(share());

    rx.pipe(filter(res => !!res)).subscribe(userResponse => {
      this.currentIntegrationPlan = userResponse ? userResponse : null;
      this.store.dispatch(requestActions.get(this.filter.id));
      this.chdet.detectChanges();
      this.scrollToPosition();
    });
  }

  // Sichere aktuelle Scrollposition um diese nach dem Speichern wieder aufzurufen.
  // Notwendig, da sich das Expansion Panel nach dem Speichern je nach Validierung schließt und wieder öffnet, was die natürliche Scroll-Position verfälscht.
  storeScrollPosition(): void {
    this.scrollPosition = document.getElementsByClassName('center')[0]?.scrollTop ?? 0;
  }

  // Scrollt die Seite auf eine exakte vertikale Position.
  scrollToPosition(yPos: number = this.scrollPosition, timeout: number = 300): void {
    setTimeout(function() {
      document.getElementsByClassName('center')[0].scrollTop = yPos;
    }, timeout);
  }

  deleteFrom(array: FormArray, index: number): void {
    array.removeAt(index);
    this.recalculateIndex(array);
  }

  /*
    Da die Reihenfolge einiger Formularelemente wichtig sind, muss der Index
    beim Anlegen und Löschen von FormArray-Inhalten neu berechnet werden.
  */
  recalculateIndex(array: FormArray): void {
    array.controls.forEach((group, index) => {
      group.patchValue({
        nr: index + 1
      });
    });
  }

  onDisable(evt: any) {
    console.log(evt);
  }

  addToFormArray(array: FormArray, groupToAdd: FormGroup, name: string, hasLimitedLength: boolean, requiredFields?: any) {
    if (!hasLimitedLength || array.length < 5) {
      this.formHelper.addNewEntry(array, _.cloneDeep(groupToAdd), requiredFields ? requiredFields : {});
      this.recalculateIndex(array);
    } else {
      this.notify.showNotification('Es dürfen maximal 5 ' + name + ' angelegt werden.', 'notify-danger');
    }
  }

  disableButtonSave(): boolean {
    const leistungsgruppeCondition = !!this.form.getRawValue().datumFeststellungZustaendigkeit
      ? !this.form.getRawValue().leistungenMedizinischeReha
        && !this.form.getRawValue().leistungenSozialeTeilhabe
        && !this.form.getRawValue().leistungenTeilhabeArbeitsleben
        && !this.form.getRawValue().leistungenTeilhabeBildung
      : false;

    return !this.form.valid
      || leistungsgruppeCondition
      || this.validators.erstattungsverfahren.mustBeUnset()
      || this.validators.antrag.atLeastOneLeistungsgruppeSelected();
  }

  navigateBack() {
    this.router.navigateByUrl(this.returnUrl);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.chdet.detach();
  }

  /**
   * Getter
   */

  get entscheidung(): FormGroup {
    return this.form.controls.entscheidung as FormGroup;
  }

  get erstattungsverfahren(): FormArray {
    return this.form.get('erstattungsverfahren') as FormArray;
  }

  get widersprueche(): FormArray {
    return this.form.get('widersprueche') as FormArray;
  }

  get klagen(): FormArray {
    return this.form.get('klagen') as FormArray;
  }

  get erstattungsantrag(): FormArray {
    return this.form.get('erstattungsantrag') as FormArray;
  }

  get gutachten(): FormArray {
    return this.form.get('gutachten') as FormArray;
  }

}
