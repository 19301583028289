import { Responsibility } from 'app/responsibility/responsibility';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CrudState, selectorsFor, reducersFor } from 'app/reducers/crud.reducer';
import {
  SET_SELECTED,
  GET_OWN_ID,
  GET_OWN_ID_DONE,
  GET_OWN_ID_ERROR,
  SET_RESPONSIBILITY_FILTER,
  SetResponsibilityFilter
} from 'app/responsibility/actions/responsibility.actions';
import * as _ from 'lodash';

export interface ResponsibilityState extends CrudState<Responsibility> {
  // Beispiel zur Erweiterung des CrudStates
  selectedResponsibility: Responsibility | null;
  isOwnID: boolean;
}

const initialState: ResponsibilityState = {
  selectedResponsibility: null,
  isOwnID: false,
  list: [],
  currentEntity: {},
  listSettings: {
    page: 0,
    filter: [],
    sort: encodeURIComponent(JSON.stringify({
      active: 'traegerID',
      direction: 'asc',
    })),
    size: 10
  },
  paginatorOptions: {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
  },
  loading: false
};

export function responsibilityReducer(state = initialState, action) {
  const crudReduced = reducersFor<Responsibility>('responsibility', state, action);

  if (crudReduced !== false) {
    return crudReduced;
  }

  switch (action.type) {
    case SET_SELECTED:
      return { ..._.cloneDeep(state), selectedResponsibility: action.payload };

    case GET_OWN_ID:
      return { ..._.cloneDeep(state), loading: true };

    case GET_OWN_ID_DONE:
      return { ..._.cloneDeep(state), isOwnID: action.id, loading: true };

    case GET_OWN_ID_ERROR:
      return { ..._.cloneDeep(state), loading: false };

    case SET_RESPONSIBILITY_FILTER:
      return setFilter(state, action);

    default:
      return state;
  }
}

function setFilter(state: ResponsibilityState, action: SetResponsibilityFilter): ResponsibilityState {

  const mergedSettings = _.merge({}, initialState.listSettings, action.payload);

  return {
    ..._.cloneDeep(state),
    listSettings: _.isEmpty(action.payload) ? initialState.listSettings : mergedSettings
  };
}

// Selectors
export const selectResponsibility = createFeatureSelector<ResponsibilityState>('responsibility');
export const responsibilitySelectors = selectorsFor<Responsibility>(selectResponsibility);
export const selectSelectedResponsibility = createSelector(selectResponsibility, state => state.selectedResponsibility);
export const selectOwnID = createSelector(selectResponsibility, state => state.isOwnID);
